import React, { useContext, useEffect, useRef, useState } from 'react';
import { useContent } from '../../../hooks/use-content';
import cx from '../../../utils/classnames';
import styles from './navDropdown.module.scss';
import { FdsChevron } from '../chevron/fds-chevron';
import { PointsHeroContext } from '../../../context/points-hero-context';
import { formatPoints } from '../../../utils/format.utils';
import ServiceHandler from '../../../services/service-handler';
function NavDropdown(_a) {
    var _b;
    var children = _a.children, active = _a.active, setTab = _a.setTab, className = _a.className, ariaLabel = _a.ariaLabel, mobileTitle = _a.mobileTitle;
    var getValueByTitle = useContent('nav-bar')[0].getValueByTitle;
    var _c = useState(false), open = _c[0], setOpen = _c[1];
    var theButton = useRef();
    var totalPoints = useContext(PointsHeroContext)[0].totalPoints;
    useEffect(function () {
        if (open) {
            document.addEventListener('mousedown', doClose);
        }
        else {
            document.removeEventListener('mousedown', doClose);
        }
        return function () { return document.removeEventListener('mousedown', doClose); };
    }, [open]);
    var doClose = function () {
        setOpen(false);
        setTimeout(function () { return theButton.current.focus(); }, 10);
    };
    var renderTabbar = function () { return (React.createElement("div", { className: cx(styles.container, className), role: 'tablist', onMouseDown: doClose }, children
        .filter(function (t) { return t; })
        .map(function (tab) {
        return React.cloneElement(tab, {
            key: tab.props.id,
            setTab: setTab,
            active: active === tab.props.id,
        });
    }))); };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: { display: 'none' } }, renderTabbar()),
        React.createElement("div", { className: styles.buttonContainer },
            React.createElement("button", { className: cx(styles.openButton, className, 'openButton', (_b = {}, _b[styles.open] = open, _b)), onMouseDown: function (e) {
                    e.preventDefault();
                    if (open)
                        doClose();
                    else
                        setOpen(true);
                }, onKeyDown: function (e) {
                    if (e.code === 'Enter') {
                        e.preventDefault();
                        if (open)
                            doClose();
                        else
                            setOpen(true);
                    }
                }, ref: theButton, id: ariaLabel },
                mobileTitle,
                React.createElement(FdsChevron, { direction: open ? 'up' : 'down' })),
            open && renderTabbar(),
            totalPoints != null && (React.createElement("div", { className: styles.starContainer },
                React.createElement("div", { className: styles.totalPoints },
                    formatPoints(totalPoints),
                    " "),
                React.createElement("div", { className: styles.points }, ServiceHandler.ConfigService.isFPR && getValueByTitle('points')),
                ServiceHandler.ConfigService.isLAR && (React.createElement("img", { className: styles.img, src: ServiceHandler.ConfigService.AEM_BASE_URL + getValueByTitle('lar-icon') })))))));
}
export default NavDropdown;
