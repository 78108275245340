import React, { useState, useEffect, useContext } from 'react';
import { useContent } from '../../../hooks/use-content';
import styles from './generic-banner.module.scss';
import { CtaNoArrorLink } from '../../../components/common/cta/cta';
import { useNavigateTo } from '../../../hooks/use-navigateTo';
import ServiceHandler from '../../../services/service-handler';
import { PointsHeroContext } from '../../../context/points-hero-context';
import { useAmplitude } from '../../../hooks/use-amplitude';
export var BANNERTYPES;
(function (BANNERTYPES) {
    BANNERTYPES["NO_ACTIVITY_POINTS_EXPIRE"] = "no activity";
    BANNERTYPES["POINTS_EXPIRE"] = "points expire";
    BANNERTYPES["ACTIVATE"] = "activate";
})(BANNERTYPES || (BANNERTYPES = {}));
export var GlobalBanners = function () {
    var cfg = ServiceHandler.ConfigService;
    var pointExpirationDate = useContext(PointsHeroContext)[0].pointExpirationDate;
    var expirationDate = new Date(pointExpirationDate);
    var _a = useState(cfg.isFPR && BANNERTYPES.NO_ACTIVITY_POINTS_EXPIRE), bannerType = _a[0], setBannerType = _a[1];
    var _b = useContext(PointsHeroContext)[0], totalPoints = _b.totalPoints, memberStatus = _b.memberStatus;
    useEffect(function () {
        if (pointExpirationDate) {
            var today = new Date();
            var time_difference = (expirationDate === null || expirationDate === void 0 ? void 0 : expirationDate.getTime()) - (today === null || today === void 0 ? void 0 : today.getTime());
            var days_difference = time_difference / (1000 * 60 * 60 * 24);
            if (days_difference <= 90) {
                setBannerType(BANNERTYPES.POINTS_EXPIRE);
                return;
            }
        }
        if (!window.location.href.includes('activate-account') && (totalPoints || memberStatus != null)) {
            if (memberStatus === 'I') {
                setBannerType(BANNERTYPES.ACTIVATE);
                return;
            }
        }
    }, [pointExpirationDate, memberStatus]);
    if (bannerType == BANNERTYPES.POINTS_EXPIRE)
        return React.createElement(PointsExpire, null);
    if (bannerType == BANNERTYPES.ACTIVATE && memberStatus == 'I')
        return React.createElement(ActivateBanner, null);
    return null;
};
export var NoActivityPointsExpirationBanner = function () { return React.createElement(GenericBanner, { content: 'v2/banner/announcement-banner' }); };
export var PointsExpire = function () { return React.createElement(GenericBanner, { content: 'v2/banner/points-expire-banner' }); };
export var ActivateBanner = function () { return React.createElement(GenericBanner, { content: 'v2/banner/activate-account-banner' }); };
export var GenericBanner = function (_a) {
    var content = _a.content;
    var getValueByTitle = useContent(content)[0].getValueByTitle;
    var navigateTo = useNavigateTo().navigateTo;
    var pointExpirationDate = useContext(PointsHeroContext)[0].pointExpirationDate;
    var disableGlobalBanner = "disableGlobalBanner";
    var logAmplitudeEvent = useAmplitude().logAmplitudeEvent;
    var _b = useState(!sessionStorage.getItem(disableGlobalBanner)), show = _b[0], setShow = _b[1];
    return show && (React.createElement("div", null,
        React.createElement("div", { className: styles.flexContainer1 },
            React.createElement("div", { className: styles.flexContainer2 },
                React.createElement("div", { className: styles.icon }),
                React.createElement("div", { className: styles.flexContainer3 },
                    React.createElement("div", { className: styles.text },
                        React.createElement("span", { className: styles.textSpacing }, getValueByTitle('disclaimer-text').replace('{pointExpirationDate}', new Date(pointExpirationDate).toLocaleDateString())),
                        React.createElement(CtaNoArrorLink, { onClick: function () {
                                navigateTo(getValueByTitle('cta-link'));
                            }, id: styles.cta, tracking: getValueByTitle('cta-label') }, getValueByTitle('cta-label'))))),
            React.createElement("button", { onClick: function () {
                    logAmplitudeEvent("banner close tapped", {
                        title: getValueByTitle('title'),
                    });
                    sessionStorage.setItem(disableGlobalBanner, 'true');
                    setShow(false);
                }, className: styles.closeIcon },
                React.createElement("div", null)))));
};
